import { useState, useEffect } from 'react';
import { Home, Building, Fan, Flame, Droplets, Thermometer, Instagram, Facebook, Phone, Mail, Clock, Menu, X } from 'lucide-react';
import { Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';
import AboutUs from './pages/AboutUs.tsx';
import Services from './pages/Services.tsx';
import EnergySavings from './pages/EnergySavings.tsx';
import Contact from './pages/Contact.tsx';

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log('App mounted');
    setIsMenuOpen(false);
  }, [location.pathname]);

  const [activeTab, setActiveTab] = useState('residential');

  const services = [
    {
      icon: Fan,
      title: 'Air Conditioning',
      description: 'Expert AC installation, repair, and maintenance services. We work with all major brands and offer 24/7 emergency repairs. Our certified technicians ensure your home stays cool and comfortable all summer long',
      iconColor: 'text-blue-600',
      bgColor: 'bg-blue-50'
    },
    {
      icon: Flame,
      title: 'Heating',
      description: 'Complete furnace and heating system services including installation, maintenance, and emergency repairs. We specialize in energy-efficient solutions to keep your home warm while reducing energy costs',
      iconColor: 'text-orange-500',
      bgColor: 'bg-orange-50'
    },
    {
      icon: Droplets,
      title: 'Water Heaters',
      description: 'Professional installation and service of traditional tank and tankless water heaters. Our experts help you choose the perfect system for your home needs and ensure reliable hot water year-round',
      iconColor: 'text-orange-500',
      bgColor: 'bg-orange-50'
    },
    {
      icon: Thermometer,
      title: 'Indoor Air Quality',
      description: 'Comprehensive air quality solutions including humidifiers, air purifiers, and ventilation systems. Breathe easier with our professional indoor air quality services and maintenance programs',
      iconColor: 'text-blue-600',
      bgColor: 'bg-blue-50'
    }
  ];

  const tabs = [
    {
      id: 'residential',
      label: 'Residential',
      icon: Home,
      content: {
        title: 'Home Comfort Solutions',
        description: 'Complete HVAC services for your home comfort needs.',
        features: [
          'AC & Furnace Installation',
          'Emergency Repairs',
          'Seasonal Maintenance',
          'Indoor Air Quality Solutions'
        ]
      }
    },
    {
      id: 'commercial',
      label: 'Commercial',
      icon: Building,
      content: {
        title: 'Commercial HVAC Services',
        description: 'Reliable HVAC solutions for businesses and commercial properties.',
        features: [
          'Commercial System Installation',
          'Preventive Maintenance',
          'Energy Efficiency Upgrades',
          'Building Automation Systems'
        ]
      }
    }
  ];

  try {
    return (
      <main className="flex-grow">
        {/* Navigation */}
        <nav className="bg-white shadow-sm fixed w-full z-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-20">
              {/* Logo */}
              <div className="flex-shrink-0 flex items-center space-x-3">
                <img
                  className="h-10 w-auto"
                  src="/images/BHHlogo.png"
                  alt="Be Happy Heating Logo"
                />
                <div className="text-sm md:text-xl">
                  <h1 className="tracking-wider font-bold text-gray-900 uppercase">
                    Be Happy Heating & AC
                  </h1>
                </div>
              </div>

              {/* Desktop Menu */}
              <div className="hidden md:flex items-center space-x-8">
                <Link to="/" className="text-gray-700 hover:text-blue-600 px-3 py-2 text-sm font-medium">
                  Home
                </Link>
                <Link to="/about" className="text-gray-700 hover:text-blue-600 px-3 py-2 text-sm font-medium">
                  About Us
                </Link>
                <Link to="/services" className="text-gray-700 hover:text-blue-600 px-3 py-2 text-sm font-medium">
                  Services
                </Link>
                <Link to="/energy-savings" className="text-gray-700 hover:text-blue-600 px-3 py-2 text-sm font-medium">
                  Energy Saving Upgrades
                </Link>
                <Link to="/contact" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
                  Contact Us
                </Link>
              </div>

              {/* Mobile menu button */}
              <div className="md:hidden flex items-center">
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-blue-600 hover:bg-gray-100"
                >
                  {isMenuOpen ? (
                    <X className="block h-6 w-6" />
                  ) : (
                    <Menu className="block h-6 w-6" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white">
              <Link to="/" className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50 rounded-md">
                Home
              </Link>
              <Link to="/about" className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50 rounded-md">
                About Us
              </Link>
              <Link to="/services" className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50 rounded-md">
                Services
              </Link>
              <Link to="/energy-savings" className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50 rounded-md">
                Energy Saving Upgrades
              </Link>
              <Link to="/contact" className="block px-3 py-2 text-base font-medium text-blue-600 hover:bg-blue-50 rounded-md">
                Contact Us
              </Link>
            </div>
          </div>
        </nav>

        {/* Add padding to account for fixed navbar */}
        <div className="h-20"></div>

        <Routes>
          <Route path="/" element={
            <>
              {/* Hero Section */}
              <div className="relative bg-[#1a365d]">
                {/* Content */}
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 md:py-32">
                  <div className="text-center">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white">
                      Your Comfort Is Our Priority
                    </h1>
                    <div className="mt-8 flex gap-4 justify-center">
                      <Link to="/contact" className="btn-primary">
                        Schedule Service
                      </Link>
                      <a href="#services" className="btn-secondary bg-white/10 hover:bg-white/20 text-white border-white/20">
                        View Services
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Technician Section */}
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <div className="grid md:grid-cols-2 gap-12 items-center">
                  {/* Left Content */}
                  <div className="space-y-6">
                    <h2 className="text-3xl md:text-4xl font-bold text-secondary-900">
                      Expert HVAC Services You Can Trust
                    </h2>
                    <p className="text-lg text-secondary-600 leading-relaxed">
                      Our certified technicians bring years of experience and expertise to every job. We pride ourselves on delivering reliable, efficient, and professional HVAC solutions for both residential and commercial clients.
                    </p>
                    <ul className="space-y-4">
                      {[
                        'NATE-Certified Technicians',
                        '24/7 Emergency Services',
                        'Comprehensive Maintenance Plans',
                        'Industry-Leading Equipment'
                      ].map((item, index) => (
                        <li key={index} className="flex items-center text-secondary-600">
                          <svg className="w-5 h-5 text-primary-600 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          {item}
                        </li>
                      ))}
                    </ul>
                    <div className="pt-4">
                      <Link to="/contact" className="btn-primary">
                        Schedule a Service
                      </Link>
                    </div>
                  </div>

                  {/* Right Image */}
                  <div className="relative">
                    <div className="relative rounded-2xl overflow-hidden shadow-xl">
                      <img
                        src="/images/technician-measuring.jpg"
                        alt="HVAC Technician performing diagnostics"
                        className="w-full h-[600px] object-cover object-center"
                      />
                      {/* Optional decorative elements */}
                      <div className="absolute -bottom-6 -right-6 w-24 h-24 bg-primary-100 rounded-full -z-10" />
                      <div className="absolute -top-6 -left-6 w-32 h-32 bg-primary-50 rounded-full -z-10" />
                    </div>
                    {/* Stats overlay */}
                    <div className="absolute bottom-4 left-4 right-4 bg-white/90 backdrop-blur-sm rounded-xl p-4 shadow-lg">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="text-center">
                          <div className="text-2xl font-bold text-primary-600">10+</div>
                          <div className="text-sm text-secondary-600">Years of Experience</div>
                        </div>
                        <div className="text-center">
                          <div className="text-2xl font-bold text-primary-600">100%</div>
                          <div className="text-sm text-secondary-600">Customer Satisfaction</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Services Cards */}
              <div id="services" className="py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-secondary-900 sm:text-4xl">
                      Our Services
                    </h2>
                    <p className="mt-4 text-xl text-secondary-600">
                      Comprehensive HVAC solutions for every need
                    </p>
                  </div>

                  <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {services.map(({ icon: Icon, title, description, iconColor, bgColor }) => (
                      <div
                        key={title}
                        className="card group"
                      >
                        <div className="flex items-center mb-4">
                          <div className={`w-12 h-12 ${bgColor} rounded-lg flex items-center justify-center mr-4 group-hover:scale-110 transition-transform duration-200`}>
                            <Icon className={`w-6 h-6 ${iconColor}`} />
                          </div>
                          <h3 className="text-xl font-semibold text-secondary-900">
                            {title}
                          </h3>
                        </div>
                        <p className="text-secondary-600 leading-relaxed">
                          {description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Customer Type Tabs */}
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                  <div className="border-b border-slate-200">
                    <div className="flex">
                      {tabs.map(({ id, label, icon: Icon }) => (
                        <button
                          key={id}
                          onClick={() => setActiveTab(id)}
                          className={`
                            flex items-center px-6 py-4 text-sm font-medium
                            ${activeTab === id 
                              ? 'text-blue-600 border-b-2 border-blue-600' 
                              : 'text-slate-600 hover:text-slate-900'
                            }
                          `}
                        >
                          <Icon className="w-5 h-5 mr-2" />
                          {label}
                        </button>
                      ))}
                    </div>
                  </div>

                  {tabs.map(({ id, content }) => (
                    <div
                      key={id}
                      className={`p-8 ${activeTab === id ? 'block' : 'hidden'}`}
                    >
                      <h2 className="text-2xl font-bold text-slate-900">
                        {content.title}
                      </h2>
                      <p className="mt-4 text-slate-600">
                        {content.description}
                      </p>
                      <ul className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {content.features.map((feature, index) => (
                          <li
                            key={index}
                            className="flex items-center text-slate-600"
                          >
                            <span className="w-2 h-2 bg-blue-600 rounded-full mr-3" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>

              {/* Call to Action */}
              <div className="mt-16 text-center p-8">
                <h2 className="text-2xl font-bold text-slate-900 mb-4">
                  Need HVAC Service or a Free Estimate?
                </h2>
                <p className="text-slate-600 mb-6">
                  Contact us for a free estimate on new installations or repairs
                </p>
                <Link
                  to="/contact"
                  className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Call Now
                </Link>
              </div>
            </>
          } />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/energy-savings" element={<EnergySavings />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/test" element={<div className="pt-20">Test Route</div>} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>

        {/* Footer */}
        <footer className="bg-[#1a365d] text-white mt-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Company Info */}
              <div>
                <h3 className="text-2xl font-bold mb-6">Be Happy Heating LLC</h3>
                <div className="space-y-4">
                  <Link to="/contact" className="flex items-center hover:text-blue-200 group">
                    <Phone className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
                    <span className="text-lg font-medium">
                      (720) 936-6218
                    </span>
                  </Link>
                  <Link to="/contact" className="flex items-center hover:text-blue-200 group">
                    <Mail className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
                    <span className="text-lg font-medium">
                      bhheating@gmail.com
                    </span>
                  </Link>
                </div>
              </div>

              {/* Hours of Operation */}
              <div>
                <h3 className="text-xl font-bold mb-4">Hours of Operation</h3>
                <div className="space-y-2">
                  <div className="flex items-start">
                    <Clock className="w-5 h-5 mr-2 mt-1" />
                    <div>
                      <p>Monday - Friday: 7:00 AM - 6:00 PM</p>
                      <p>Saturday: 8:00 AM - 2:00 PM</p>
                      <p>Sunday: Closed</p>
                      <p className="mt-2 text-blue-200">24/7 Emergency Service Available</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Social & Connect */}
              <div>
                <h3 className="text-xl font-bold mb-4">Connect With Us</h3>
                <div className="flex space-x-4">
                  <a 
                    href="https://instagram.com/behappyheating" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="hover:text-blue-200 transition-colors"
                  >
                    <Instagram className="w-6 h-6" />
                  </a>
                  <a 
                    href="https://facebook.com/behappyheating" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="hover:text-blue-200 transition-colors"
                  >
                    <Facebook className="w-6 h-6" />
                  </a>
                </div>
                <div className="mt-6">
                  <p className="text-sm text-blue-200">
                    Proudly serving the Denver Metro Area and surrounding communities
                  </p>
                </div>
              </div>
            </div>

            {/* Copyright */}
            <div className="border-t border-white/10 mt-12 pt-8 text-center text-sm">
              <p>© {new Date().getFullYear()} Be Happy Heating LLC. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </main>
    );
  } catch (error) {
    console.error('Render error:', error);
    return <div>Something went wrong. Please try again.</div>;
  }
}