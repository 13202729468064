import { 
  Fan, 
  Flame, 
  Droplets, 
  Thermometer, 
  Wrench, 
  Shield, 
  Settings, 
  Filter,
  CircuitBoard,
  Wind 
} from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Services() {
  const serviceCategories = [
    {
      title: "Heating Services",
      icon: Flame,
      iconColor: "text-orange-500",
      services: [
        {
          name: "Furnace Installation",
          icon: CircuitBoard,
          iconColor: "text-orange-600",
          description: "Energy-efficient furnace solutions from trusted brands. Expert installation with proper sizing and optimization for your space.",
          features: ["High-efficiency models", "Professional installation", "Extended warranties", "Financing available"]
        },
        {
          name: "Heating Repair",
          icon: Wrench,
          iconColor: "text-orange-500",
          description: "Fast, reliable furnace and heating system repairs. Available 24/7 for emergency service.",
          features: ["Same-day service", "All brands serviced", "Upfront pricing", "Guaranteed work"]
        },
        {
          name: "Preventive Maintenance",
          icon: Settings,
          iconColor: "text-orange-400",
          description: "Regular maintenance to keep your heating system running efficiently and prevent costly breakdowns.",
          features: ["Annual tune-ups", "Safety inspections", "Filter changes", "Performance optimization"]
        }
      ]
    },
    {
      title: "Cooling Services",
      icon: Fan,
      iconColor: "text-blue-500",
      services: [
        {
          name: "AC Installation",
          icon: CircuitBoard,
          iconColor: "text-blue-600",
          description: "Complete air conditioning installation services with energy-efficient options for any budget.",
          features: ["Energy Star systems", "Professional sizing", "Expert installation", "Performance testing"]
        },
        {
          name: "AC Repair",
          icon: Wrench,
          iconColor: "text-blue-500",
          description: "Comprehensive AC repair services to keep your system running at peak performance.",
          features: ["Emergency repairs", "All makes & models", "Certified technicians", "Parts warranty"]
        },
        {
          name: "AC Maintenance",
          icon: Settings,
          iconColor: "text-blue-400",
          description: "Regular maintenance to ensure efficient operation and extend system life.",
          features: ["Seasonal tune-ups", "Coil cleaning", "Performance checks", "Refrigerant levels"]
        }
      ]
    },
    {
      title: "Indoor Air Quality",
      icon: Wind,
      iconColor: "text-green-500",
      services: [
        {
          name: "Air Purification",
          icon: Filter,
          iconColor: "text-green-600",
          description: "Advanced air purification systems to remove contaminants and improve indoor air quality.",
          features: ["HEPA filtration", "UV light treatment", "Whole-house systems", "Allergen removal"]
        },
        {
          name: "Humidity Control",
          icon: Droplets,
          iconColor: "text-green-500",
          description: "Solutions for maintaining optimal humidity levels in your home.",
          features: ["Humidifiers", "Dehumidifiers", "Smart controls", "Balanced comfort"]
        },
        {
          name: "Ventilation Systems",
          icon: Wind,
          iconColor: "text-green-400",
          description: "Fresh air solutions to improve indoor air quality and reduce contaminants.",
          features: ["ERV/HRV systems", "Duct cleaning", "Air exchanges", "Smart ventilation"]
        }
      ]
    },
    {
      title: "Water Solutions",
      icon: Droplets,
      iconColor: "text-blue-500",
      services: [
        {
          name: "Water Filtration",
          icon: Filter,
          iconColor: "text-blue-600",
          description: "Comprehensive water filtration systems for clean, safe water throughout your home.",
          features: ["Whole-house filtration", "Reverse osmosis", "Water testing", "Filter maintenance"]
        },
        {
          name: "Water Heaters",
          icon: Thermometer,
          iconColor: "text-blue-500",
          description: "Installation and service of traditional and tankless water heaters.",
          features: ["Tankless options", "High-efficiency units", "Fast installation", "Emergency service"]
        }
      ]
    }
  ];

  return (
    <div className="pt-20">
      {/* Hero Section */}
      <div className="bg-[#1a365d] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold text-center">Our Services</h1>
          <p className="mt-4 text-xl text-center text-white/90">
            Comprehensive HVAC and home comfort solutions
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Service Categories */}
        {serviceCategories.map((category, index) => (
          <div key={index} className="mb-16">
            <div className="flex items-center mb-8">
              <category.icon className={`w-8 h-8 ${category.iconColor} mr-3`} />
              <h2 className="text-3xl font-bold text-gray-900">{category.title}</h2>
            </div>
            
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {category.services.map((service, serviceIndex) => (
                <div key={serviceIndex} className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
                  <div className="flex items-center mb-4">
                    <div className="w-12 h-12 bg-gray-50 rounded-lg flex items-center justify-center mr-4">
                      <service.icon className={`w-6 h-6 ${service.iconColor}`} />
                    </div>
                    <h3 className="text-xl font-bold text-gray-900">{service.name}</h3>
                  </div>
                  <p className="text-gray-600 mb-4">{service.description}</p>
                  <ul className="space-y-2">
                    {service.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center text-gray-600">
                        <svg className="w-4 h-4 text-blue-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* Diagnostic & Emergency Services */}
        <div className="bg-gray-50 rounded-xl p-8 mb-16">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold text-gray-900">Diagnostic & Emergency Services</h2>
            <p className="mt-4 text-xl text-gray-600">
              Expert troubleshooting and 24/7 emergency support
            </p>
          </div>
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-bold text-gray-900 mb-4">Diagnostic Services</h3>
              <ul className="space-y-3">
                {[
                  "Advanced system diagnostics",
                  "Performance testing",
                  "Energy efficiency analysis",
                  "Safety inspections",
                  "Equipment life expectancy assessment"
                ].map((item, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <Wrench className="w-5 h-5 text-blue-600 mr-2" />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-bold text-gray-900 mb-4">Emergency Services</h3>
              <ul className="space-y-3">
                {[
                  "24/7 emergency response",
                  "Same-day service available",
                  "Fully stocked service vehicles",
                  "Emergency temporary solutions",
                  "Priority service for maintenance plan members"
                ].map((item, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <Shield className="w-5 h-5 text-blue-600 mr-2" />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="mt-16 text-center p-8">
          <h2 className="text-2xl font-bold text-slate-900 mb-4">
            Need HVAC Service or a Free Estimate?
          </h2>
          <p className="text-slate-600 mb-6">
            Contact us at (720) 936-6218 for a free estimate on new installations or repairs
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Call Now
          </Link>
        </div>
      </div>
    </div>
  );
} 