import { Phone, Mail, Clock } from 'lucide-react';

export default function Contact() {
  return (
    <div className="pt-20">
      {/* Hero Section */}
      <div className="bg-[#1a365d] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold text-center">Contact Us</h1>
          <p className="mt-4 text-xl text-center text-white/90">
            Schedule service or request a free consultation
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Contact Form */}
          <div className="md:col-span-2">
            <div className="bg-white rounded-xl shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Schedule Service</h2>
              
              <form
                action="https://formspree.io/f/mwpvnbkv"
                method="POST"
                className="space-y-6"
              >
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <label className="block text-base font-medium text-gray-900 mb-2">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      required
                      className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base"
                    />
                  </div>
                  <div>
                    <label className="block text-base font-medium text-gray-900 mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      required
                      className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base"
                    />
                  </div>
                  <div>
                    <label className="block text-base font-medium text-gray-900 mb-2">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      required
                      className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base"
                    />
                  </div>
                  <div>
                    <label className="block text-base font-medium text-gray-900 mb-2">
                      Service Type
                    </label>
                    <select
                      name="serviceType"
                      className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base"
                    >
                      <option value="residential">Residential</option>
                      <option value="commercial">Commercial</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label className="block text-base font-medium text-gray-900 mb-2">
                    Service Needed
                  </label>
                  <select
                    name="serviceNeeded"
                    className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base"
                  >
                    <option value="">Select a service</option>
                    <option value="ac-repair">AC Repair</option>
                    <option value="ac-installation">AC Installation</option>
                    <option value="heating-repair">Heating Repair</option>
                    <option value="heating-installation">Heating Installation</option>
                    <option value="maintenance">Maintenance</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div>
                  <label className="block text-base font-medium text-gray-900 mb-2">
                    Message
                  </label>
                  <textarea
                    name="message"
                    rows={4}
                    className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base"
                  ></textarea>
                </div>

                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-base font-medium text-gray-900 mb-2">
                      Preferred Contact Method
                    </label>
                    <div className="mt-2 space-y-2">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="preferredContact"
                          value="phone"
                          id="contact-phone"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        />
                        <label htmlFor="contact-phone" className="ml-2 text-base text-gray-700">
                          Phone
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="preferredContact"
                          value="email"
                          id="contact-email"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        />
                        <label htmlFor="contact-email" className="ml-2 text-base text-gray-700">
                          Email
                        </label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-base font-medium text-gray-900 mb-2">
                      Preferred Time
                    </label>
                    <div className="mt-2 space-y-2">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="preferredTime"
                          value="morning"
                          id="time-morning"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        />
                        <label htmlFor="time-morning" className="ml-2 text-base text-gray-700">
                          Morning (8AM - 12PM)
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="preferredTime"
                          value="afternoon"
                          id="time-afternoon"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        />
                        <label htmlFor="time-afternoon" className="ml-2 text-base text-gray-700">
                          Afternoon (12PM - 5PM)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full md:w-auto px-8 py-3 bg-blue-600 text-white text-lg font-medium rounded-lg hover:bg-blue-700 transition-colors duration-200"
                >
                  Submit Request
                </button>
              </form>
            </div>
          </div>

          {/* Contact Information */}
          <div>
            <div className="bg-white rounded-xl shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-8">Contact Information</h2>
              <div className="space-y-8">
                <div className="flex items-start">
                  <Phone className="w-7 h-7 text-blue-600 mt-1" />
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold text-gray-900">Phone</h3>
                    <p className="mt-2">
                      <a href="tel:720-936-6218" className="text-lg text-blue-600 hover:text-blue-800 font-medium">
                        (720) 936-6218
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <Mail className="w-7 h-7 text-blue-600 mt-1" />
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold text-gray-900">Email</h3>
                    <p className="mt-2">
                      <a href="mailto:bhheating@gmail.com" className="text-lg text-blue-600 hover:text-blue-800 font-medium">
                        bhheating@gmail.com
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <Clock className="w-7 h-7 text-blue-600 mt-1" />
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold text-gray-900">Hours</h3>
                    <div className="mt-2 space-y-2 text-base">
                      <p className="text-gray-700">Monday - Friday: 7:00 AM - 6:00 PM</p>
                      <p className="text-gray-700">Saturday: 8:00 AM - 2:00 PM</p>
                      <p className="text-gray-700">Sunday: Closed</p>
                      <p className="text-blue-600 font-semibold mt-4">24/7 Emergency Service Available</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 