import TestimonialsCarousel from '../components/TestimonialsCarousel.tsx';
import { Link } from 'react-router-dom';

export default function AboutUs() {
  const testimonials = [
    {
      text: "Be Happy Heating transformed our home comfort. Their team was professional, punctual, and went above and beyond to ensure everything was perfect. Best HVAC service we've ever had!",
      author: "Michael Thompson",
      role: "Homeowner in Denver"
    },
    {
      text: "Outstanding service from start to finish. They installed our new heating system efficiently and the difference in our energy bills has been remarkable. Highly recommend their expertise!",
      author: "Sarah Martinez",
      role: "Local Business Owner"
    },
    {
      text: "The technicians were knowledgeable and took the time to explain everything. They fixed our AC issues quickly and their preventive maintenance program has kept our system running smoothly.",
      author: "David Wilson",
      role: "Residential Customer"
    },
    {
      text: "Exceptional customer service! They responded quickly to our emergency call and had our heating system back up and running in no time. Fair pricing and excellent work.",
      author: "Jennifer Parker",
      role: "Property Manager"
    }
  ];

  return (
    <div className="pt-20">
      {/* Hero Section */}
      <div className="bg-[#1a365d] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold text-center">About Be Happy Heating</h1>
          <p className="mt-4 text-xl text-center text-white/90">
            Your trusted partner in home comfort since 2014
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Our Story Section */}
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Story</h2>
            <div className="prose prose-lg">
              <p>
                Founded in 2014, Be Happy Heating has been serving the Denver Metro Area with exceptional HVAC services. 
                What started as a small family operation has grown into a trusted name in home comfort, while maintaining 
                our commitment to personalized service and customer satisfaction.
              </p>
              <p>
                Our mission is simple: to provide reliable, efficient, and affordable heating and cooling solutions 
                while treating every home as if it were our own. We believe in building lasting relationships with 
                our customers through honest work and transparent communication.
              </p>
            </div>
          </div>
          <div className="relative">
            <img 
              src="/images/team-photo.jpg"
              alt="Be Happy Heating Team" 
              className="rounded-lg shadow-xl"
            />
          </div>
        </div>

        {/* Add Testimonials Section before the Mission section */}
        <div className="bg-gray-50 py-16 mb-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold text-gray-900">
                What Our Customers Say
              </h2>
              <p className="mt-4 text-xl text-gray-600">
                Don't just take our word for it
              </p>
            </div>
            
            <TestimonialsCarousel testimonials={testimonials} />
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Why Choose Us</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Experienced Team",
                description: "Our NATE-certified technicians bring years of expertise to every job, ensuring quality work every time."
              },
              {
                title: "24/7 Availability",
                description: "We understand that HVAC emergencies don't wait for business hours. That's why we're available 24/7."
              },
              {
                title: "Fair & Transparent Pricing",
                description: "No hidden fees or surprise costs. We provide upfront pricing and explain all your options."
              },
              {
                title: "Customer First",
                description: "Your satisfaction is our priority. We work with you to find the best solutions for your needs and budget."
              },
              {
                title: "Licensed & Insured",
                description: "Full peace of mind with comprehensive insurance coverage and all required licenses."
              },
              {
                title: "Local Expertise",
                description: "Deep understanding of Denver's unique climate challenges and HVAC requirements."
              }
            ].map((item, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-bold text-gray-900 mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Our Values Section */}
        <div className="bg-gray-50 rounded-xl p-8 mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Our Values</h2>
          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                title: "Integrity",
                description: "Honest recommendations and transparent pricing in everything we do."
              },
              {
                title: "Excellence",
                description: "Commitment to the highest standards of service and workmanship."
              },
              {
                title: "Reliability",
                description: "Consistent, dependable service you can count on."
              },
              {
                title: "Innovation",
                description: "Embracing the latest HVAC technologies and best practices."
              }
            ].map((value, index) => (
              <div key={index} className="text-center">
                <h3 className="text-xl font-bold text-gray-900 mb-2">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <div className="mt-16 text-center p-8">
          <h2 className="text-2xl font-bold text-slate-900 mb-4">
            Need HVAC Service or a Free Estimate?
          </h2>
          <p className="text-slate-600 mb-6">
            Contact us at (720) 936-6218 for a free estimate on new installations or repairs
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Call Now
          </Link>
        </div>
      </div>
    </div>
  );
} 