import { Zap, Leaf, Calculator, TrendingDown } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function EnergySavings() {
  const upgrades = [
    {
      title: "High-Efficiency HVAC Systems",
      icon: Zap,
      description: "Modern HVAC systems with SEER ratings of 16+ that significantly reduce energy consumption while improving comfort.",
      benefits: [
        "Up to 50% energy savings compared to older units",
        "Enhanced temperature control",
        "Quieter operation",
        "Better humidity management"
      ],
      iconColor: "text-yellow-500",
      bgColor: "bg-yellow-50"
    },
    {
      title: "Smart Thermostats",
      icon: Calculator,
      description: "Intelligent temperature control systems that learn your preferences and optimize energy usage.",
      benefits: [
        "Automatic temperature adjustments",
        "Remote control via smartphone",
        "Energy usage reports",
        "Maintenance reminders"
      ]
    },
    {
      title: "Ductwork Optimization",
      icon: TrendingDown,
      description: "Comprehensive duct sealing and insulation services to minimize energy loss and improve system efficiency.",
      benefits: [
        "Reduced energy waste",
        "Improved air distribution",
        "Better indoor air quality",
        "Lower utility bills"
      ]
    },
    {
      title: "Green Energy Solutions",
      icon: Leaf,
      description: "Environmentally friendly heating and cooling options that reduce your carbon footprint.",
      benefits: [
        "Solar-ready HVAC systems",
        "Heat pump technology",
        "Energy recovery ventilators",
        "Eco-friendly refrigerants"
      ],
      iconColor: "text-green-600",
      bgColor: "bg-green-50"
    }
  ];

  return (
    <div className="pt-20">
      {/* Hero Section */}
      <div className="bg-[#1a365d] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold text-center">Energy Saving Upgrades</h1>
          <p className="mt-4 text-xl text-center text-white/90">
            Reduce your energy costs while improving comfort
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Upgrades Grid */}
        <div className="grid md:grid-cols-2 gap-8">
          {upgrades.map((upgrade, index) => (
            <div key={index} className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-blue-50 rounded-lg flex items-center justify-center mr-4">
                  <upgrade.icon 
                    className={`w-6 h-6 ${
                      upgrade.iconColor || "text-blue-600"
                    }`} 
                  />
                </div>
                <h3 className="text-xl font-bold text-gray-900">{upgrade.title}</h3>
              </div>
              <p className="text-gray-600 mb-4">{upgrade.description}</p>
              <ul className="space-y-2">
                {upgrade.benefits.map((benefit, benefitIndex) => (
                  <li key={benefitIndex} className="flex items-center text-gray-600">
                    <svg className="w-4 h-4 text-blue-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Call to Action */}
        <div className="mt-16 text-center p-8">
          <h2 className="text-2xl font-bold text-slate-900 mb-4">
            Need HVAC Service or a Free Estimate?
          </h2>
          <p className="text-slate-600 mb-6">
            Contact us at (720) 936-6218 for a free estimate on new installations or repairs
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Call Now
          </Link>
        </div>
      </div>
    </div>
  );
} 